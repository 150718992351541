var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-skeleton-loader',{style:({height: '100%', width: '100%'}),attrs:{"loading":_vm.GetOrdersActions,"type":"card-heading, image@3"}},[(_vm.orders.length>0)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('v-row',[_c('v-col',[_c('base-text-field',{staticStyle:{"min-width":"225px"},attrs:{"prepend-inner-icon":'$iconify_search-outlined',"placeholder":"Поиск и фильтр","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onCreateOrderClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $iconify_plus-circle-outlined ")]),_vm._v(" Создать счет на оплату ")],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('base-table',{attrs:{"class-name":"table-balance-operations","headers":_vm.headers,"items":_vm.filtered_orders,"is-custom-header":false,"total-count":_vm.filtered_orders.length,"word-operations":['счет', 'счета', 'счетов'],"pagination":{
            sortBy: 'created_at',
            descending: 'descending',
          },"options":{
            itemsPerPage: 5
          }},on:{"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_c('date-column',{attrs:{"value":item.created_at}})]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('status-column',{attrs:{"icon":item.status_enum.icon,"text":item.status_enum.text,"color":item.status_enum.color}})]}},{key:"item.value_rub",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"body-s-semibold"},[_vm._v(_vm._s(item.value_rub)+" ₽")])]}},{key:"item.method",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.MERCHANT_ORDER_METHOD_ENUM.find(item.method).text))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","loading":item.loading}},on),[_c('v-icon',[_vm._v("$iconify_feather-more-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.getActions(item)),function(menuItem,index){return _c('v-list-item',{key:index,on:{"click":function($event){return menuItem.action(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"neutral-500"}},[_vm._v(" "+_vm._s(menuItem.icon)+" ")])],1),_c('v-list-item-title',{class:{
                      'body-s-medium' : true,
                      'neutral-500--text':true,
                    }},[_vm._v(" "+_vm._s(menuItem.title)+" ")])],1)}),1)],1)]}}],null,true)})],1)],1)],1):_c('base-empty-block-page',{attrs:{"title":"Счета отсутсвуют","description":"Здесь будут отображаться все выставленные нами счета.","action-icon":"$iconify_plus-circle-outlined","action-text":"Создать счет на оплату","action":""},on:{"action":_vm.onCreateOrderClick},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('v-img',{attrs:{"src":require("@/assets/png/settings-dummy.png"),"width":"186px","height":"156px"}})]},proxy:true}])}),(_vm.createDialog)?_c('create-order-dialog',{on:{"close":_vm.onCreateDialogClose},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }