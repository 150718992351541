<template>
  <div class="container-title-input">
    <v-form
      ref="form"
      v-model="details.valid"
    >
      <div class="mto">
        <h3 class="title-h3">
          Общая информация
        </h3>
        <!--        <base-text-field-->
        <!--            v-model="details.legalName"-->
        <!--            :validate-on-blur="true"-->
        <!--            placeholder="Юридическое название организации"-->
        <!--            outlined-->
        <!--            dense-->
        <!--        />-->
        <div class="block-two-input">
          <div class="simple-width-md">
          <p class="desc-13 color-text-dark font-weight-600">
               Адрес
          </p>
          <base-text-field
            error-style="vuetify"
            v-model="details.address"
            placeholder="Адрес"
            outlined
            clearable
            class="mg-input-custom"
            maxlength="255"
            :rules="[
              v => !!v || 'Поле обязательно',
            ]"
          />
          </div>
          <!--          <base-text-field-->
          <!--            v-model="phoneFax"-->
          <!--            placeholder="Телефон / факс"-->
          <!--            outlined-->
          <!--            class="mg-input-custom"-->
          <!--          />-->
          <div class="simple-width-md">
          <p style="margin-bottom: 0px" class="color-text-dark font-weight-600">
                Телефон
          </p>
          <vue-tel-input-vuetify
            v-model="details.phoneFax"
            placeholder="Телефон"
            :max-len="12"
            required
            :rules="[
              v => !!v || 'Поле обязательно'
            ]"
          />
          </div>
        </div>
        <div class="block-two-input">
          <div class="simple-width-md">
          <p class="desc-13 color-text-dark font-weight-600">
                ФИО партнера
          </p>
          <base-text-field
            error-style="vuetify"
            v-model="details.fullNameHead"
            placeholder="ФИО партнера"
            outlined
            clearable
            class="mg-input-custom"
            maxlength="255"
            :rules="[
              v => !!v || 'Поле обязательно',
            ]"
          />
          </div>
          <div class="simple-width-md">
          <p class="desc-13 color-text-dark font-weight-600">
                ИНН партнера
          </p>
          <base-text-field
            error-style="vuetify"
            v-model="details.enterTin"
            placeholder="ИНН партнера"
            outlined
            class="mg-input-custom"
            maxlength="12"
            clearable
            :rules="[              
              v => !v || !! /^(([0-9]){12})$/i.test(v) || 'Не соответствует формату',
            ]"
          />
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
  export default {
    name: 'Individual',
    data () {
      return {
        details: {
          valid: false,
          legalName: '',
          address: '',
          phoneFax: '',
          positionOfHead: '',
          fullNameHead: '',
          enterBin: '',
          enterTin: '',
          enterCheckpoint: '',
          checkingAccount: '',
          enterBic: '',
          bankName: '',
          correspondentAccount: '',
          org_type: 'FL',
        },
      }
    },
    computed: {
      requisites () {
        return this.$store.getters['settings/organization/requisites']
      },
      merchant () {
        return this.$store.getters['settings/organization/merchant']
      },
    },
    watch: {
      merchant (v) {
        this.details = this.requisites
        this.details.org_type = 'FL'
      },
      details: {
        handler (v) {
          this.$store.commit('settings/organization/requisites', v)
        },
        deep: true,
      },
    },
    mounted () {
      this.details = this.requisites
      this.details.org_type = 'FL'
    },
    methods: {
      validateForm() {
        this.$refs.form.validate()
      }
    },
  }
</script>

<style scoped>
  .mr-20 {
    margin-right: 20px;
    margin-top: 10px;
  }
  .simple-width-md-a {
    max-width: 350px;
    width: 100%;
  }
  .simple-width-md {
    max-width: 350px;
    width: 100%;
    margin-top: 10px;
  }

  .mto {
    margin-top: 34px;
  }
  .title-h3 {
    //margin: 0 0 24px;
    margin: 0;
  }
  .block-two-input {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  .mg-input-custom {
    max-width: 328px;
    width: 100%;
  }
  .input-country {
    height: 45px;
    border-color: #D7D7E0;
    margin-bottom: 26px;
    border-radius: 10px;
    outline: none;
  }
  .input-country.vue-tel-input:focus-within {
    box-shadow: none;
    border-color: #4776E6;
  }
  .vti__dropdown.open {
    outline: none;
  }
</style>
