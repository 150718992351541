<template>
  <div class="container-title-input">
    <v-form
      ref="form"
      v-model="details.valid"
    >
      <div class="mto">
        <h3 class="title-h3">
          Общая информация
        </h3>
        <p class="desc-13 color-text-dark font-weight-600">
                Юридическое название организации
        </p>
        <base-text-field
          error-style="vuetify"
          v-model="details.legalName"
          :validate-on-blur="true"
          placeholder="Юридическое название организации"
          outlined
          maxlength="255"
          dense
        />
        <div class="block-two-input">
          <div class="simple-width-md mr-20">
          <p class="desc-13 color-text-dark font-weight-600">
                Адрес
          </p>
          <base-text-field
            error-style="vuetify"
            v-model="details.address"
            placeholder="Адрес"
            outlined
            maxlength="255"
            :rules="[
                  v => !!v || 'Поле обязательно',
                ]"
            class="mg-input-custom"
          />
          </div>
<!--          <base-text-field-->
<!--            v-model="phoneFax"-->
<!--            placeholder="Телефон / факс"-->
<!--            outlined-->
<!--            class="mg-input-custom"-->
<!--          />-->
          <div class="simple-width-md mr-20">
          <p class="desc-13 color-text-dark font-weight-600">
                Телефон
          </p>
          <vue-tel-input-vuetify
            v-model="details.phoneFax"
            class="mg-input-custom input-country"
            placeholder="Телефон"
            :maxLen="12"
          />
          </div>
        </div>
        <div class="simple-width-md-a">
          <p class="desc-13 color-text-dark font-weight-600">
                ФИО партнера
          </p>
          <base-text-field
            error-style="vuetify"
            v-model="details.fullNameHead"
            placeholder="ФИО партнера"
            outlinedclass="mg-input-custom"
            maxlength="255"
            :rules="[
                  v => !!v || 'Поле обязательно',
                ]"
          />
        </div>
      </div>
      <div>
        <h3 style="margin-top: 0!important;">
          Реквизиты
        </h3>
        <div class="block-two-input">
          <div class="simple-width-md mr-20">
            <p class="desc-13 color-text-dark font-weight-600">
              ОГРНИП
            </p>
            <base-text-field
              error-style="vuetify"
              v-model="details.enterBin"
              placeholder="Введите ОГРНИП"
              outlined
              maxlength="15"
              :rules="[
                    v => !!v || 'Поле обязательно',
                    v => !! /^(([0-9]){15})$/i.test(v) || 'Не соответствует формату'
              ]"
            />
          </div>
          <div class="simple-width-md mr-20">
            <p class="desc-13 color-text-dark font-weight-600">
              ИНН
            </p>
            <base-text-field
              error-style="vuetify"
              v-model="details.enterTin"
              placeholder="Введите ИНН"
              outlined
              maxlength="12"
              :rules="[
                    v => !!v || 'Поле обязательно',
                    v => !! /^(([0-9]){12})$/i.test(v) || 'Не соответствует формату',
              ]"
            />
          </div>
        </div>
        <div class="block-two-input">
          <div class="simple-width-md mr-20">
            <p class="desc-13 color-text-dark font-weight-600">
              Расчетный счет
            </p>
            <base-text-field
              error-style="vuetify"
              v-model="details.checkingAccount"
              placeholder="Введите № расчетного счета"
              outlined
              maxlength="20"
              :rules="[
                    v => (v.length == 0 || /^(([0-9]){20})$/i.test(v)) || 'Не соответствует формату',
              ]"
            />
          </div>
          <div class="simple-width-md">
            <p class="desc-13 color-text-dark font-weight-600">
              БИК
            </p>
            <base-text-field
                error-style="vuetify"
                v-model="details.enterBic"
                placeholder="Введите БИК"
                maxlength="9"
                outlined
                :rules="[
                    v => (v.length == 0 || /^(([0-9]){9})$/i.test(v)) || 'Не соответствует формату',
                  ]"
            />
          </div>
        </div>
        <div class="block-two-input" style="margin-bottom: 64px">
          <div class="simple-width-md mr-20">
            <p class="desc-13 color-text-dark font-weight-600">
              Название банка
            </p>
            <base-text-field
                error-style="vuetify"
                v-model="details.bankName"
                maxlength="255"
                placeholder="Введите название банка"
                outlined
                class="mg-input-custom"
            />
          </div>
          <div class="simple-width-md">
            <p class="desc-13 color-text-dark font-weight-600">
              Корреспондентский счет
            </p>
            <base-text-field
                error-style="vuetify"
                maxlength="20"
                v-model="details.correspondentAccount"
                placeholder="Введите № корреспондентского счета"
                outlined
                :rules="[
                    v => (v.length == 0 || /^(([0-9]){20})$/i.test(v)) || 'Не соответствует формату',
                  ]"
                class="mg-input-custom"
            />
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
  export default {
    name: 'IndividualEntrepreneur',
    data () {
      return {
        details: {
          valid: false,
          legalName: '',
          address: '',
          phoneFax: '',
          positionOfHead: '',
          fullNameHead: '',
          enterBin: '',
          enterTin: '',
          enterCheckpoint: '',
          checkingAccount: '',
          enterBic: '',
          bankName: '',
          correspondentAccount: '',
          org_type: 'IP',
        },
      }
    },
    computed: {
      requisites () {
        return this.$store.getters['settings/organization/requisites']
      },
      merchant () {
        return this.$store.getters['settings/organization/merchant']
      },
    },
    watch: {
      merchant (v) {
        this.details = this.requisites
        this.details.org_type = 'IP'
      },
      details: {
        handler (v) {
          this.$store.commit('settings/organization/requisites', v)
        },
        deep: true,
      },
    },
    methods: {
      validateForm() {
        this.$refs.form.validate()
      }
    },
    mounted () {
      this.details = this.requisites
      this.details.org_type = 'IP'
    },
  }
</script>

<style scoped>
  .mto {
    margin-top: 34px;
    margin-bottom: 26px;
  }
  .title-h3 {
    margin: 0 0 24px;
  }
  .block-two-input {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .mr-20 {
    margin-right: 0px;
    margin-top: 10px;
  }

  .simple-width-md {
    max-width: 328px;
    width: 100%;
    margin-top: 10px;
  }

  .simple-width-sm {
    max-width: 154px;
    width: 100%;
    margin-top: 10px;
  }

  .desc-13 {
    margin-bottom: 8px!important;
  }
  .mg-input-custom {
    max-width: 328px;
    width: 100%;
  }
  .input-country {
    height: 45px;
    border-color: #D7D7E0;
    margin-bottom: 26px;
    border-radius: 10px;
    outline: none;
  }
  .input-country.vue-tel-input:focus-within {
    box-shadow: none;
    border-color: #4776E6;
  }
  .vti__dropdown.open {
    outline: none;
  }
</style>
