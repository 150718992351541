var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container-title-input"},[_c('v-form',{ref:"form",model:{value:(_vm.details.valid),callback:function ($$v) {_vm.$set(_vm.details, "valid", $$v)},expression:"details.valid"}},[_c('div',{staticClass:"mto"},[_c('h3',{staticClass:"title-h3"},[_vm._v(" Общая информация ")]),_c('p',{staticClass:"desc-13 color-text-dark font-weight-600"},[_vm._v(" Юридическое название организации ")]),_c('base-text-field',{attrs:{"error-style":"vuetify","placeholder":"Юридическое название организации","outlined":"","dense":"","maxlength":"255","rules":[
                function (v) { return !!v || 'Поле обязательно'; } ]},model:{value:(_vm.details.legalName),callback:function ($$v) {_vm.$set(_vm.details, "legalName", $$v)},expression:"details.legalName"}}),_c('div',{staticClass:"block-two-input"},[_c('div',{staticClass:"simple-width-md mr-20"},[_c('p',{staticClass:"desc-13 color-text-dark font-weight-600"},[_vm._v(" Адрес ")]),_c('base-text-field',{staticClass:"mg-input-custom",attrs:{"error-style":"vuetify","placeholder":"Адрес","outlined":"","maxlength":"255","rules":[
                function (v) { return !!v || 'Поле обязательно'; } ]},model:{value:(_vm.details.address),callback:function ($$v) {_vm.$set(_vm.details, "address", $$v)},expression:"details.address"}})],1),_c('div',{staticClass:"simple-width-md mr-20"},[_c('p',{staticClass:"desc-13 color-text-dark font-weight-600"},[_vm._v(" Телефон ")]),_c('vue-tel-input-vuetify',{staticClass:"mg-input-custom input-country",attrs:{"placeholder":"Телефон","maxLen":12},model:{value:(_vm.details.phoneFax),callback:function ($$v) {_vm.$set(_vm.details, "phoneFax", $$v)},expression:"details.phoneFax"}})],1)]),_c('div',{staticClass:"block-two-input"},[_c('div',{staticClass:"simple-width-md mr-20"},[_c('p',{staticClass:"desc-13 color-text-dark font-weight-600"},[_vm._v(" ФИО руководителя ")]),_c('base-text-field',{staticClass:"mg-input-custom",attrs:{"error-style":"vuetify","placeholder":"ФИО руководителя","outlined":"","maxlength":"255","rules":[
                function (v) { return !!v || 'Поле обязательно'; } ]},model:{value:(_vm.details.fullNameHead),callback:function ($$v) {_vm.$set(_vm.details, "fullNameHead", $$v)},expression:"details.fullNameHead"}})],1),_c('div',{staticClass:"simple-width-md mr-20"},[_c('p',{staticClass:"desc-13 color-text-dark font-weight-600"},[_vm._v(" Должность руководителя ")]),_c('base-text-field',{staticClass:"mg-input-custom",attrs:{"error-style":"vuetify","placeholder":"Должность руководителя","outlined":"","maxlength":"255","rules":[
                function (v) { return !!v || 'Поле обязательно'; } ]},model:{value:(_vm.details.positionOfHead),callback:function ($$v) {_vm.$set(_vm.details, "positionOfHead", $$v)},expression:"details.positionOfHead"}})],1)])],1),_c('div',[_c('h3',{staticStyle:{"margin-top":"0!important"}},[_vm._v(" Реквизиты ")]),_c('div',{staticClass:"block-two-input"},[_c('div',{staticClass:"simple-width-md mr-20"},[_c('p',{staticClass:"desc-13 color-text-dark font-weight-600"},[_vm._v(" ОГРН ")]),_c('base-text-field',{attrs:{"error-style":"vuetify","placeholder":"Введите ОГРН","outlined":"","maxlength":"13","rules":[
                  function (v) { return !!v || 'Поле обязательно'; },
                  function (v) { return !! /^(([0-9]){13})$/i.test(v) || 'Не соответствует формату'; } ]},model:{value:(_vm.details.enterBin),callback:function ($$v) {_vm.$set(_vm.details, "enterBin", $$v)},expression:"details.enterBin"}})],1),_c('div',{staticClass:"simple-width-sm mr-20"},[_c('p',{staticClass:"desc-13 color-text-dark font-weight-600"},[_vm._v(" ИНН ")]),_c('base-text-field',{attrs:{"error-style":"vuetify","placeholder":"Введите ИНН","outlined":"","maxlength":"10","rules":[
                  function (v) { return !!v || 'Поле обязательно'; },
                  function (v) { return !! /^(([0-9]){10})$/i.test(v) || 'Не соответствует формату'; } ]},model:{value:(_vm.details.enterTin),callback:function ($$v) {_vm.$set(_vm.details, "enterTin", $$v)},expression:"details.enterTin"}})],1),_c('div',{staticClass:"simple-width-sm"},[_c('p',{staticClass:"desc-13 color-text-dark font-weight-600"},[_vm._v(" КПП ")]),_c('base-text-field',{attrs:{"error-style":"vuetify","placeholder":"Введите КПП","outlined":"","maxlength":"9","rules":[
                  function (v) { return !!v || 'Поле обязательно'; },
                  function (v) { return !! /^(([0-9]){9})$/i.test(v) || 'Не соответствует формату'; } ]},model:{value:(_vm.details.enterCheckpoint),callback:function ($$v) {_vm.$set(_vm.details, "enterCheckpoint", $$v)},expression:"details.enterCheckpoint"}})],1)]),_c('div',{staticClass:"block-two-input"},[_c('div',{staticClass:"simple-width-md mr-20"},[_c('p',{staticClass:"desc-13 color-text-dark font-weight-600"},[_vm._v(" Расчетный счет ")]),_c('base-text-field',{attrs:{"error-style":"vuetify","placeholder":"Введите № расчетного счета","maxlength":"20","outlined":"","rules":[
                  function (v) { return (v.length == 0 || /^(([0-9]){20})$/i.test(v)) || 'Не соответствует формату'; } ]},model:{value:(_vm.details.checkingAccount),callback:function ($$v) {_vm.$set(_vm.details, "checkingAccount", $$v)},expression:"details.checkingAccount"}})],1),_c('div',{staticClass:"simple-width-md"},[_c('p',{staticClass:"desc-13 color-text-dark font-weight-600"},[_vm._v(" БИК ")]),_c('base-text-field',{attrs:{"error-style":"vuetify","placeholder":"Введите БИК","maxlength":"9","outlined":"","rules":[
                  function (v) { return (v.length == 0 || /^(([0-9]){9})$/i.test(v)) || 'Не соответствует формату'; } ]},model:{value:(_vm.details.enterBic),callback:function ($$v) {_vm.$set(_vm.details, "enterBic", $$v)},expression:"details.enterBic"}})],1)]),_c('div',{staticClass:"block-two-input"},[_c('div',{staticClass:"simple-width-md mr-20"},[_c('p',{staticClass:"desc-13 color-text-dark font-weight-600"},[_vm._v(" Название банка ")]),_c('base-text-field',{staticClass:"mg-input-custom",attrs:{"error-style":"vuetify","maxlength":"255","placeholder":"Введите название банка","outlined":""},model:{value:(_vm.details.bankName),callback:function ($$v) {_vm.$set(_vm.details, "bankName", $$v)},expression:"details.bankName"}})],1),_c('div',{staticClass:"simple-width-md"},[_c('p',{staticClass:"desc-13 color-text-dark font-weight-600"},[_vm._v(" Корреспондентский счет ")]),_c('base-text-field',{staticClass:"mg-input-custom",attrs:{"error-style":"vuetify","maxlength":"20","placeholder":"Введите № корреспондентского счета","outlined":"","rules":[
                  function (v) { return (v.length == 0 || /^(([0-9]){20})$/i.test(v)) || 'Не соответствует формату'; } ]},model:{value:(_vm.details.correspondentAccount),callback:function ($$v) {_vm.$set(_vm.details, "correspondentAccount", $$v)},expression:"details.correspondentAccount"}})],1)])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }