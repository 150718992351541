<template>
  <div>
    <div class="container-cash-info">
      <div class="title-cash">
        <h2 class="title-h2">
          {{ merchant.name }}
        </h2>
        <div class="block-cash-btn">
          <span class="cash color-green-text font-weight-bold title-h2">{{
            balance_rub.toLocaleString(undefined, { style: 'currency', currency: 'RUB' })
          }}</span>
          <v-btn color="primary" @click="onAddBalanceClick">
            <v-icon left>
              $iconify_feather-credit-card
            </v-icon>
            <span>Пополнить</span>
          </v-btn>
        </div>
      </div>
      <div class="info-block">
        <v-row align="center">
          <v-col cols="3" class="body-s-semibold">
            Статус
          </v-col>
          <v-col cols="9" :class="{
            'body-m-medium': true,
            'success--text': merchant.active,
            'error--text': !merchant.active,
          }">
            {{ merchant.active ? 'Активный' : 'Неактивный' }}
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="3" class="body-s-semibold">
            Соглашение
          </v-col>
          <v-col cols="9" class="body-m-medium neutral-700--text">
            <v-row no-gutters align="center">
              <v-col cols="auto">
                <span>Принято {{ $moment.utc(merchant.created_at).local().format('D MMMM, YYYY') }}</span>
              </v-col>
              <v-col>
                <v-btn icon x-small color="primary" @click="policyDialog = true">
                  <v-icon>$iconify_tabler-external-link</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="3" class="body-s-semibold">
            Договор
          </v-col>
          <v-col cols="9" class="body-m-medium neutral-700--text">
            <v-row v-if="merchant.contract_number" no-gutters align="center">
              <v-col cols="auto">
                <span>№{{ merchant.contract_number }} <span v-if="merchant.contract_date"> от {{
                  $moment(merchant.contract_date).format('D MMMM, YYYY') }}</span></span>
              </v-col>
              <!-- <v-col>
                <v-btn
                  icon
                  x-small
                  color="primary"
                >
                 <v-icon>$iconify_feather-download</v-icon>
                </v-btn>
              </v-col> -->
            </v-row>
            <span v-else>-</span>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-row v-if="merchant.tariff.name != 'Пробный тариф'"> 
      <v-col>
        <v-row>
          <v-col class="title-cash">
            <h2 class="title-h2">
              Тарифный план
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3 class="title-h3 font-weight-600">
              {{ merchant.tariff.name }}
            </h3>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="auto" class="body-s-semibold">
            Стоимость
          </v-col><v-col class="body-m-medium neutral-700--text">
            <template v-if="merchant.tariff.duration_price > 0">
              {{ Math.round(merchant.tariff.duration_price / 100).toLocaleString(undefined, {
                style: 'currency', currency:
                  'RUB'
              }) }}
              {{ durationTypeTrans[merchant.tariff.duration_type] }}
            </template>
            <template v-else>
              Бесплатно
            </template>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="auto" class="body-s-semibold">
            Статус тарифа
          </v-col> <v-col cols="9" :class="{
            'body-m-medium': true,
            'success--text': tariffStatus === 'active',
            'error--text': tariffStatus !== 'active',
          }">
            {{ tariffStatusStr }}
          </v-col>
        </v-row>
        <v-row v-if="merchant.merchant_tariff.end_at" align="center">
          <v-col cols="auto" class="body-s-semibold">
            Дата окончания
          </v-col>
          <v-col class="body-m-medium neutral-700--text">
            {{ new Date(merchant.merchant_tariff.end_at).toLocaleString() }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- <v-row><v-col>{{ merchant }}</v-col></v-row> -->
    <create-balance-payment-dialog v-if="createDialog" v-model="createDialog" @close="onCreateDialogClose" />
    <policy-text-dialog v-model="policyDialog" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { MERCHANT_ORDER_METHOD } from '@/models/enums'

export default {
  name: 'Balance',
  components: {
    CreateBalancePaymentDialog: () => import('../dialogs/CreateBalancePaymentDialog.vue'),
    PolicyTextDialog: () => import('@/views/auth//components/PolicyTextDialog.vue'),
  },
  data() {
    return {
      createDialog: false,
      policyDialog: false,
      durationTypeTrans: {
        once: 'единоразово',
        minute: 'в минуту',
        hour: 'в час',
        day: 'в день',
        month: 'в месяц',
        year: 'в год',
      },
    }
  },
  computed: {
    merchant() {
      return this.$store.getters['settings/organization/merchant']
    },
    balance_rub() {
      return this.merchant.balance / 100
    },
    balance_rub_str() {
      return this.merchant ? this.merchant.balance_rub : '0'
    },
    isTariffExpired() {
      return this.merchant.merchant_tariff.end_at !== null && this.$moment(this.merchant.merchant_tariff.end_at) < this.$moment.now()
    },
    tariffStatus() {
      if (this.merchant.merchant_tariff.active) {
        if (this.isTariffExpired) {
          return 'expired'
        } else {
          return 'active'
        }
      } else {
        return 'inactive'
      }
    },
    tariffStatusStr() {
      return {
        active: 'Действует',
        inactive: 'Отключен',
        expired: 'Истек',
      }[this.tariffStatus]
    },
  },
  methods: {
    ...mapActions({
      GetOrderPdf: 'auth/merchant/GetOrderPdf',
    }),
    onAddBalanceClick() {
      this.createDialog = true
    },
    onCreateDialogClose(newOrder) {
      if (!newOrder) return
      if (newOrder.method === MERCHANT_ORDER_METHOD.METHOD_ORDER_ORG) {
        this.downloadOrderClick(newOrder)
        this.$router.replace({ name: 'SettingsRequisites', hash: '#orders' })
      } else if (newOrder.method === MERCHANT_ORDER_METHOD.METHOD_SBERBANK) {
        if (newOrder.sber_order) {
          window.open(newOrder.sber_order.form_url, '_blank')
        }
      }
    },
    async downloadOrderClick(order) {
      try {
        order.GetOrderPdfAction = true
        await this.GetOrderPdf(order)
      } catch (e) {
        console.error(e)
      } finally {
        order.GetOrderPdfAction = false
      }
    },
  },
}
</script>

<style scoped>
.block-cash-btn {
  display: flex;
  align-items: center;
}

.cash {
  margin-right: 34px;
}

.title-cash {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.custom-gradient-bg {
  display: flex;
  align-items: center;
}

.card-outline {
  margin-right: 8px;
}

.info-wrap {
  display: flex;
  justify-content: space-between;
}

.info-block {
  max-width: 320px;
  width: 100%;
}

.container-cash-info {
  margin-top: 68px;
  margin-bottom: 40px;
}

.info-wrap:not(:last-of-type) {
  margin-bottom: 12px;
}

.text-img {
  display: flex;
  align-items: center;
  min-width: 192px;
}

.mlo-6 {
  margin-left: 6px;
}

.mgo {
  margin-bottom: 12px;
}

.mbo-20 {
  margin-bottom: 20px;
}

.mbo-30 {
  margin-bottom: 30px;
}

.block-info {
  border: 1px solid #f2f2f7;
  box-sizing: border-box;
  box-shadow: 0px 24px 20px -16px rgba(88, 93, 106, 0.1);
  border-radius: 10px;
  background: #ffffff;
  width: 100%;
  max-width: 676px;
  padding: 20px 10px 20px 20px;
  display: flex;
}

.block-info:not(:last-of-type) {
  margin-bottom: 26px;
}

.img-block-info img {
  width: 47px;
  height: 47px;
}

.switch-block {
  display: flex;
  align-items: center;
}

.block-switch-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 22px;
  margin-bottom: 14px;
}

.block-switch-tariff {
  width: 100%;
  margin-left: 6px;
}

.info-tariff {
  display: flex;
  align-items: center;
  max-width: 300px;
  width: 100%;
  justify-content: space-between;
}

.btn-text {
  width: 164px;
  display: flex;
  align-items: center;
}
</style>
